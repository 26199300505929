import { ResetCSS } from '@pancakeswap/uikit'
import 'antd/dist/antd.css'
import 'antd/dist/antd.dark.css'
import BigNumber from 'bignumber.js'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { Fragment } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, useStore } from 'state'
import dynamic from 'next/dynamic'

import Providers from '../Providers'
import GlobalStyle from '../style/Global'
import '../style/animation.css'
import '../style/global.css'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const ErrorBoundary = dynamic(import('../components/ErrorBoundary'), { ssr: false })
const PublicLayout = dynamic(import('layouts/PublicLayout'), { ssr: false })

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function MyApp(props: AppProps) {
  const { pageProps } = props
  const store = useStore(pageProps.initialReduxState)

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="noodp,noydir" />

        {/* <!-- Link --> */}
        <link rel="apple-touch-icon" type="image/x-icon" href="/favicon.ico" />
        <link rel="shortcut icon" type="image/png" href="/favicon.ico" />

        {/* <!-- Og --> */}
        <meta property="og:title" content="WELCOME TO DXDLABS" />
        <meta
          property="og:description"
          content="We bring together IT experts who leverage data-driven insights and cutting-edge digital transformation strategies to empower our clients. With extensive experience in software development, we foster sustainable growth for businesses of all sizes."
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="WELCOME TO DXDLABS" />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="DXD LABS" />
        <meta property="og:image" content="/og-image.png" />
        <meta property="og:url" content="https://dapp.babycate.org" />

        {/* <!-- Twitter --> */}
        <meta name="twitter:title" content="WELCOME TO DXDLABS" />
        <meta
          name="twitter:description"
          content="We bring together IT experts who leverage data-driven insights and cutting-edge digital transformation strategies to empower our clients. With extensive experience in software development, we foster sustainable growth for businesses of all sizes."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@DxdLbas" />
        <meta name="twitter:image" content="/og-image.png" />
        <meta name="twitter:creator" content="@DxdLbas" />

        {/* <!-- Title --> */}
        <title>WELCOME TO DXDLABS</title>
        <meta name="keywords" content="WELCOME, TO DxD LABS, AND HIS DARING JOURNEY" />
        <meta
          name="description"
          content="We bring together IT experts who leverage data-driven insights and cutting-edge digital transformation strategies to empower our clients. With extensive experience in software development, we foster sustainable growth for businesses of all sizes."
        />
      </Head>
      <Providers store={store}>
        <ResetCSS />
        <GlobalStyle />
        <PersistGate loading={null} persistor={persistor}>
          <App {...props} />
        </PersistGate>
      </Providers>
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
  fullLayout?: boolean
  authLayout?: boolean
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? ErrorBoundary : Fragment

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const Layout = Fragment

  return (
    <ProductionErrorBoundary>
      <Layout>
        {Component.fullLayout ? (
          <Component {...pageProps} />
        ) : (
          <PublicLayout>
            <div id="google_translate_element" />
            <Component {...pageProps} />
          </PublicLayout>
        )}
      </Layout>
    </ProductionErrorBoundary>
  )
}

export default MyApp
